import React from 'react'

function Cookies() {



    const cookiesPolicy = () => {

        const barracookies = document.getElementById("cookieconsent");
        let visitasWebsite = localStorage.getItem('padariaWeb');
    
        if(localStorage && barracookies != null){
    
            if(visitasWebsite == null || visitasWebsite == ''){
                barracookies.classList.add('NewFade');
            }
            else{
                barracookies.style.display="none";
            }
    
        }
    }
    
    function clickCookie(){
    
        var visitasWebsite = localStorage.getItem('padariaWeb');
        const barracookies = document.getElementById("cookieconsent");
    
        if(barracookies != null){
            barracookies.classList.remove("NewFade")
            barracookies.classList.add("OutFade")
        }
    
        localStorage.setItem('padariaWeb', visitasWebsite + 1);
    }
    
    setTimeout(cookiesPolicy, 1000);
    

  return (
    <div className="cookieconsent" id="cookieconsent">
        <div className="texto">Este Website utiliza cookies para assegurar que tem uma boa experiência na sua visita.</div>
        <div className="buttons">
          <a id="pol" href="/politicas">Políticas de Privacidade</a>
          <a id="aceitar"  onClick={clickCookie}>Aceitar</a>
        </div>
    </div>
  )
}

export default Cookies