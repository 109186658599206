import React from 'react'
import logotipo from '../media/logo/logotipo.svg'


function Politicas() {
  return (
    <section className="Politicas">

        <a href="/">
            <img src={logotipo} alt="" />
        </a>

    <h1>Políticas de Privacidade e Cookies</h1>
    <ol>
        <li>Origem e tipo de dados pessoais recolhidos</li>
        <li>Finalidade dos Dados Pessoais</li>
        <li>Privacidade e Proteção de Dados Pessoais</li>
        <li>Prazo e conservação dos Dados Pessoais</li>
        <li>Direitos do Utilizador</li>
        <li>Política de Cookies</li>
        <li>Alterações à Política de Privacidade da Padaria da Maria .</li>
        <li>Contactos</li>
    </ol>


    <h3 style={{marginTop:"2em"}}>1. Origem e tipo de dados pessoais recolhidos</h3>
    <p> A Padaria da Maria respeita a sua privacidade e providencia medidas para proteger os dados que partilha connosco (tal como email, morada, contacto telefónico, etc.). Esta Política de Privacidade foi criada exclusivamente para explicar de que forma recolhemos os seus dados, com que intuito o fazemos, e quais são os seus direitos quanto à utilização dos mesmos.
        Ao utilizar o website, assim como outras plataformas da Padaria da Maria , 
        consideramos que concorda com nossa Política de Privacidade.
    </p>
    
    <h3 style={{marginTop:"2em"}}>2. Origem e tipo de dados pessoais recolhidos</h3>
        <p>Por Dados Pessoais entende-se toda a informação que o possa identificar enquanto pessoa singular,
            tal como nome, morada, e-mail, contacto telefónico e número fiscal.
        </p>
        <p>Estas informações podem ser recolhidas através de:</p>
        <ul>
            <li>Processo de registo no nosso website aquando da criação da sua conta de cliente;</li>
            <li>Informações de transações no ato de uma compra através do nosso website;</li>
            <li>Cópias de registos de comunicações e contactos trocados entre o utilizador e a Padaria da Maria ;</li>
            <li>Respostas fornecidas em questionários para fins de análises estatísticas;</li>
            <li>Uso, visualizações e dados técnicos, incluindo seu identificador de dispositivo ou endereço IP, quando visita o nosso website ou abre e-mails que enviamos;</li>
            <li>Pesquisas realizadas no nosso website;</li>
            <li>Preenchimento de formulários existentes no nosso website;</li>
            <li>Subscrição de Newsletters;</li>
            <li>Outras iniciativas de Marketing que tenham como objetivo a angariação de contactos.</li>
            <ul><li>Detetar possíveis tendências de mercado;</li></ul>
            <ul><li>Criação de sugestões pessoais;</li></ul>
            <ul><li>Análise do comportamento dos utilizadores de modo a melhorar a experiência na loja;</li></ul>
            <ul><li>Otimizar os nossos produtos e serviços;</li></ul>
            <li>Envio de convites para iniciativas que tenham a participação da Padaria da Maria  ;</li>
            <li>Campanhas e acontecimentos relacionados com a empresa, suas associadas ou parceiros;</li>
            <li>Detetar, investigar e impedir atividades que possam ser ilegais ou violar as nossas políticas.</li>
        </ul>
            <p>De modo a cumprir com alguns dos pressupostos acima mencionados, a Padaria da Maria  poderá transmitir Dados Pessoais do Utilizador para sociedades pertencentes à Padaria da Maria ou a terceiros subcontratados para prestação de serviços de suporte, tais como organismos financeiros, entidades de combate à fraude, prestadores de serviços tecnológicos, logísticos e serviços ao cliente e/ou serviços de análise de transações efetuados na Plataforma com o objetivo de oferecer aos utilizadores garantias suficientes no âmbito de operações comerciais, etc.</p>
            <p>Os seus dados não serão utilizados para fins diferentes daqueles para que nos deu o consentimento.</p>
            <p>Caso não pretenda fornecer os seus dados, ficará impossibilitado de adquirir os nossos produtos, ou aceder a informação sobre determinados serviços, ofertas, promoções e conteúdos relacionados com a empresa, associados ou parceiros. </p>

    <h3 style={{marginTop:"2em"}}>3. Privacidade e Proteção de Dados Pessoais</h3>
        <p>Os dados pessoais que nos indicou serão tratados com vista às finalidades descritas no ponto 2 dos presentes Termos e Condições, pela Padaria da Maria , e suas participadas.</p>
        <p>Assim, A Padaria da Maria  é a responsável pelo tratamento dos seus dados pessoais, por meios automatizados ou não, desde a sua recolha, organização, conservação até à eliminação, garantindo a confidencialidade de todos os dados fornecidos por si.</p>
        <p>A Padaria da Maria  pauta-se pelo rigoroso cumprimento das normas relativas ao tratamento de dados pessoais, que atualmente estão previstas no Regulamento (EU) 2016/679, do Parlamento Europeu e do Conselho de 27 de Abril de 2016.</p>
        <p>A Padaria da Maria  assegura que os dados recolhidos são os estritamente necessários ao cumprimento das finalidades de gestão de clientes (incluindo a definição de perfis); marketing e publicidade, faturação de compras, entregas e reclamações.</p>
        <p>A Padaria da Maria  recorre apenas a subcontratantes que apresentem garantias suficientes de aplicação do RGPD (EU 2016/679) e da política interna de privacidade e proteção de dados do responsável pelo tratamento de dados e que assegurem a defesa dos direitos do titular dos dados.</p>
    
    <h3 style={{marginTop:"2em"}}>4. Prazo e conservação dos Dados Pessoais</h3>
    <p>Os seus dados serão guardados pelo período de cinco anos a contar da concessão deste consentimento ou, caso tenha celebrado connosco contrato de compra ou prestação de serviços, os mesmos serão guardados pelo período de dez anos a contar da data da última compra efetuada, ou por tempo superior se assim a lei o estabelecer.</p>
    
    <h3 style={{marginTop:"2em"}}>5. Direitos do Utilizador</h3>
    <p>A qualquer momento, e de forma gratuita, pode exercer o direito de pedir à Padaria da Maria para:</p>
    <ul>
        <li>Aceder aos seus dados pessoais;</li>
        <li>Solicitar a retificação dos seus dados pessoais;</li>
        <li>Solicitar a eliminação dos seus dados pessoais;</li>
        <li>Solicitar a limitação do tratamento dos seus Dados Pessoais;</li>
        <li>Solicitar a oposição ou retirada de consentimentos anteriores;</li>
        <li>Solicitar a portabilidade dos seus dados para outra entidade por si indicada.</li>
    </ul>
    <p>Caso exista obrigação legalmente imposta que se sobreponha a estes direitos, A Padaria da Maria  fundamentará a respetiva recusa.</p>
    <p>Caso considere que os seus dados não estão a ser tratados em conformidade com a legislação aplicável, designadamente europeia e nacional, lembramos que tem o direito de apresentar reclamação a uma autoridade de controlo.        </p>
  
    <h3 style={{marginTop:"2em"}}>6. Política de Cookies.</h3>
    <p>Os cookies são pequenos ficheiros de dados que são transferidos da Padaria da Maria  para o disco do seu computador. Os cookies que são descarregados são seguros, não contêm vírus informáticos e não podem ser utilizados para transmitir ou armazenar informação pessoal. Geralmente, os cookies classificam-se como “de sessão” ou “permanentes”.</p>
    <p>Cookies de sessão: não permanecem no computador quando se abandona o site ou se fecha o navegador. A informação recolhida permite à Padaria da Maria analisar padrões de tráfego sobre o website. Com o passar do tempo, podemos oferecer aos utilizadores uma melhor experiência no site, melhorando continuamente o conteúdo, fazendo ajustes e facilitando a sua utilização.</p>
    <p>Cookies permanentes: permanecem no seu computador quando abandona o site https://apadariadamaria.pt , a Padaria da Maria utiliza este tipo de cookies para analisar se os utilizadores regressam ao site. Desta forma podemos saber se o site é útil, interessante e valioso para o utilizador.</p>
    <p>É possível não aceitar os cookies no seu computador selecionando o ajuste correspondente no seu navegador.</p>
    <p>Veja como pode fazê-lo nos três navegadores mais utilizados:</p>
    <ul>
      <li><a href="https://support.microsoft.com/pt-pt/help/17442/windows-internet-explorer-delete-managecookies" target="_blank">Microsoft Internet Explorer</a></li>
      <li><a href="https://support.mozilla.org/pt-PT/kb/ativar-desativar-cookies-websites-utilizam-monitorizarpreferencias?redirectlocale=pt-PT&amp;redirectslug=ativar-e-desativar-cookies-que-os-websitesutiliza" target="_blank">Mozilla Firefox</a> </li>
      <li><a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=ptBR" target="_blank">Google Chrome</a></li>
    </ul>
    <p>Devemos acrescentar que, caso não aceite os cookies, é possível que não possa utilizar todas as funcionalidades do site.</p>
   
    <h3 style={{marginTop:"2em"}}>7. Alterações à Política de Privacidade da Padaria da Maria .</h3>
    <p>A Padaria da Maria, reserva-se o direito de atualizar, periodicamente, esta Política de Privacidade. Qualquer atualização realizada, a mesma será anunciada não só através do nosso website, como através de outros meios de comunicação que a empresa entenda como mais adequados. A informação será enviada, para todos os nossos clientes ou potenciais clientes, para os meios de contacto indicados.</p>
    <p>O uso continuado do nosso website, aplicações, serviços e recursos oferecidos após tal comunicação terá valor de, e será considerado como, a sua concordância com tais alterações.</p>
    <p>A versão em vigor foi publicada em https://apadariadamaria.pt </p>
    
    <h3 style={{marginTop:"2em"}}>8. Contactos</h3>
    <p>Se tiver alguma dúvida relacionada com a nossa política de privacidade, entre em contacto connosco através dos seguintes meios:</p>
    <p>Email:geral@apadariadamaria.pt</p>
    <p>Telefone: (+351) 253 116 206</p>
    <p>Morada:&nbsp;</p>
    <p style={{marginBottom:"5em"}}>Para qualquer questão legal, pode contactar a nossa entidade competente, a Comissão Nacional de Proteção de Dados (www.cnpd.pt).</p>
    
</section>
  )
}

export default Politicas