import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import Politicas from './Paginas/Politicas';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    
    <BrowserRouter>
     <Routes>
        <Route path="/" element={<App />} />
        <Route path="politicas" element={<Politicas />} />
        <Route path='*' element={<App />} />
     </Routes>
    </BrowserRouter>


  //   <React.StrictMode>
  // </React.StrictMode>
);

