import React from 'react'
import menusvg from './menu.svg'
function FloatBtn() {
  return (
    <div className='fixed_btn' 
    style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        
    }}>
        <div className="btn_container"
        style={{
            width: '60px',
        height: '60px',
        borderRadius: '50px',
        paddingTop:'3px',
        backgroundColor: '#f1f1f1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        }}>
            <a href="https://apadariadamaria.pt/ementa/" target="_blank">
                <img src={menusvg} alt="" />
            </a>
        </div>
    </div>
  )
}

export default FloatBtn