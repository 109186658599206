import React from 'react'
import footermapa from '../../media/footer/mapa2.svg'

function Footer() {

    const HandleFooterForm = (e) => {
        e.preventDefault();

        const form = document.getElementById('formFooter');
        const bots = document.getElementById('botsfooter');
        
        // Informação dos Inputs
        const formData = {
            email: document.getElementById('emailfooter').value,
            assunto: document.getElementById('assunto').value,
            bots: document.getElementById('botsfooter').value,
        }

    
        // Verificar se o formulário está preenchido
        if( bots.value !== '' || formData.assunto === ''){
            document.getElementById('errosFooter').innerHTML = 'Preencha todos os campos corretamente';
        }
        // else if validate email pattern
        else if(!formData.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)){
            document.getElementById('errosFooter').innerHTML = 'Preencha o campo de email corretamente';
        }
        else{

                document.getElementById('errosFooter').innerHTML = '';
                document.getElementById('sucesso').innerText = 'Obrigado pelo seu contacto! O email foi enviado com sucesso.';

                // Enviar o formulário para um email 
                const newForm = new FormData(form);
                const xmlRequest = new XMLHttpRequest();
                xmlRequest.open('POST', './sendmailfooter.php', true);
                xmlRequest.send(newForm);

                form.reset();
                setTimeout(() => {
                    document.getElementById('sucesso').innerText = '';
                }, 5000);
        
        }
    }


  return (
    <footer className='Footer'>
        <div className='Footer__container'>
            <div className='Footer__container__left'>
                <img src={footermapa} alt="" />
            </div>

            <div className='Footer__container__right'>
                <div className="Footer__container__right_form">
                    <h3>Deixe o seu comentário</h3>

                    <form id="formFooter" action="sendmailfooter.php" method='post'>
                        <div className="input-group">
                            <input type="email" id="emailfooter" name="emailfooter"  placeholder="Email" required/>
                        </div>
                        
                        <div className="input-group">
                            <input type="number" id="botsfooter" name="botsfooter" style={{display:"none"}} />
                            <textarea id="assunto" name="assunto" cols="30" rows="8" placeholder='Escreva aqui a sua mensagem ou sugestão' required></textarea>
                        </div>

                        <div className="input-group">
                            <button type='submit' onClick={HandleFooterForm}> Enviar</button>
                            <p id='errosFooter' style={
                            {
                                color: 'red',
                                paddingTop: '10px',
                                fontSize: '14px',
                            }
                            }></p>
                            <p id='sucesso' style={
                            {
                                color: 'green',
                                paddingTop: '10px',
                                fontSize: '14px',
                            }
                            }></p>
                        </div>

                        

                    </form>
                </div>
                <div className="contactlinks">
                    <ul>
                        <li>
                            <a href="https://goo.gl/maps/gARpZB2SMK3fhx9V9" target="_blank">
                                Fábrica - R. dos Moinhos Novos 468 4830, Póvoa de Lanhoso 
                            </a>
                        </li>
                        <li>
                            <a href="https://goo.gl/maps/vePbY92CHbhcjUJ57" target="_blank">
                                Café - Avenida 25 de Abril n°96, Póvoa de Lanhoso, Portugal
                            </a>
                        </li>
                        <li>
                            <a href="mailto:geral@apadariadamaria.pt" target="_blank">
                                geral@apadariadamaria.pt
                            </a>
                        </li>
                        <li>
                            <a href="tel:253116206" target="_blank">
                                +351 253 116 206
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className='Footer__copyright'>
            <div className="item">
                   <p>© A Padaria da Maria <span id="autoyear">{new Date().getFullYear()}</span> </p>
                    
            </div>
            <div className="item">
                <p>
                    <a href="/politicas" target="_blank">Políticas de Privacidade e Cookies</a>
                </p>
            </div>
            <div className="item"> <a href="https://macrobiiz.com" target="_blank"> by Macrobiiz</a></div>

        </div>
    </footer>
  )
}

export default Footer